import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import PageCardProps from "./PageCardProps";

const PageCard = loadable(
    (): Promise<DefaultComponent<PageCardProps>> =>
        import(/* webpackChunkName: "PageCard" */ "./PageCard"),
);
const PageCardLoader = (props: PageCardProps): ReactElement => (
    <PageCard {...props} />
);
export default PageCardLoader;
