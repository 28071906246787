import React, { ReactElement } from "react";

import { useIsInEditMode } from "hooks";
import {
    addEditAttributes,
    addEditAttributesBlockLevel,
} from "utils/episerver";

import { EditWrapperProps } from "./EditWrapperProps";

const EditWrapper = ({
    editPropertyName,
    contentId,
    style,
    children,
}: EditWrapperProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    const attributes = editPropertyName
        ? addEditAttributes(editPropertyName)
        : contentId
          ? addEditAttributesBlockLevel(contentId)
          : undefined;

    return isInEditMode && attributes ? (
        <div {...attributes} style={style}>
            {children}
        </div>
    ) : (
        <>{children}</>
    );
};

export default React.memo(EditWrapper);
