// A function to return a string to be used for theming
const getButtonTheme = (
    theme: string,
    actionTheme: string = "black",
    variant: string = "primary",
) => {
    if (!theme || theme === "") return actionTheme;

    // There are other namings for the theme when we use white buttons
    if (["image", "white"].includes(theme || "") || actionTheme === "white")
        return "white";

    // No black primary buttons
    if (variant === "primary" && actionTheme === "black") actionTheme = "blue";

    //It should now be a valid theme name, otherwise just use the lightgray one
    if (!["lightgray", "cyan", "blue", "black"].includes(theme || ""))
        theme = "lightgray";

    const validButtons: {
        [key: string]: {
            [key: string]: string[];
        };
    } = {
        primary: {
            lightgray: ["blue", "cyan", "coral"],
            cyan: ["blue"],
            blue: ["cyan", "coral"],
        },
        outline: {
            lightgray: ["black", "coral"],
            cyan: ["blue"],
            blue: ["cyan", "coral"],
        },
    };

    // If its a valid theme and actionTheme combination return that string concenated otherwise use the fallback colored button
    if (
        validButtons[variant] &&
        validButtons[variant][theme] &&
        validButtons[variant][theme].includes(actionTheme)
    )
        return `${theme}_${actionTheme}`;
    else {
        // console.warn(
        //     "Cant load a buttonTheme with options",
        //     variant,
        //     " || theme " + theme,
        //     " || actionTheme " + actionTheme,
        // );
        return actionTheme;
    }
};

export default getButtonTheme;
